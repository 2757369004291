.card_news {
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .time {
        width: 3.5rem;
        color: #000000;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.3;
        padding: 15px;
        transition:all 0.5s;
        border-top:1px solid #000 ;
        border-bottom:1px solid #000 ;
    }

    .infomation {
        max-width: 700px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 24px;
        max-height: 560px;

        .title {
            font-size: 1.5rem;
            font-weight: 700;
            margin-bottom: 1.2rem;
            line-height: 1.3;
            letter-spacing: 0px;
            text-align: left;
        }

        .info {
            margin-bottom: 1.2rem;
            font-size: 1rem;
            line-height: 1.3;
            color: rgb(142, 142, 142);
            a{
            color: rgb(50, 50, 50);
            &:hover{
             color: rgb(42, 130, 228); 
            }
          }
        }

       
    }

    .img {
        max-width: 700px;
        height: 100%;
        position: relative;
        max-height: 560px;
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            max-height: 560px;
            
        }
        @media (max-width=960px){
            width: 100%; 
        }

        .news_card {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 350px;
            height: 140px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

}

.card_news:hover {
   .time{
        background-color: rgb(0, 61, 166);color: #fff;
        border: 0;
    }

    .infomation {
        .title {
            color: rgb(0, 61, 166);
        }
    }

}
.newstable{
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 1400px;
    padding: 32px;
    gap:32px;
    align-items: center;
    transition:all 0.5s;
    background: linear-gradient(270.00deg, rgba(160, 182, 222, 0.00), rgba(160, 182, 222, 0.0) 49.792%, rgba(160, 182, 222, 0.00) 100%);
    transition: background 0.5s ease-out;
    &:hover{
         background: linear-gradient(270.00deg, rgba(160, 182, 222, 0.00), rgba(160, 182, 222, 0.2) 49.792%, rgba(160, 182, 222, 0.00) 100%);
    }
    @media screen and (max-width: 900px){
    flex-direction:  column-reverse; 
    gap:12px;
    }
    .newsright{
    height: 100%;
    width: 35vw;
     @media (max-width:900px){
        width: 80vw;
        margin: 0 auto;
    }
     @media (max-width:640px){
        width: 90vw;
    }
}
}

