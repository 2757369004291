.top_info {
  overflow: hidden;
  .img_main {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;


        img {
            max-height: 100%;
            min-width: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
        }

        .titel {
            display: inline-block;
            color: #ffffff;
            font-size: 100px;
            font-weight: 700;
            z-index: 2;
            margin-bottom: 10px;
            .texty {
                >span {
                    font-size: 100px;
                    font-weight: 700;
                    z-index: 2;
                    margin-bottom: 10px;
                    @media screen and (max-width: 1200px) {
                        font-size: 4vw;
        
                    }
                }
            }
        }

        .bottom_direction {
            background-image: url("../../static/svg/bottom_dir.svg");
            background-repeat: no-repeat;
            background-size: contain;
            width: calc(45px / 2);
            height: calc(45px / 2);
            position: absolute;
            bottom: 20px;
            z-index: 2;
            animation: mouse 1s ease-in-out 0.1s alternate none infinite running;
        }

        span {
            color: #ffffff;
            font-size: 24px;
            z-index: 2;
            text-align: center;
            @media screen and (max-width: 1200px) {
                font-size: 2rem;

            }

        }
    img {
      max-height: 100%;
      min-width: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
    }

    .titel {
      display: inline-block;
      color: #ffffff;
      font-size: 6rem;
      font-weight: 700;
      z-index: 2;
      margin-bottom: 10px;
      

      .texty {
        > span {
          font-size: 5rem;
          font-weight: 700;
          z-index: 2;
          margin-bottom: 10px;
          @media screen and (max-width: 640px) {
           font-size: 4rem;
      }

        }
      }
    }

    .bottom_direction {
      background-image: url("../../static/svg/bottom_dir.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: calc(45px / 2);
      height: calc(45px / 2);
      position: absolute;
      bottom: 20px;
      z-index: 2;
      animation: mouse 1s ease-in-out 0.1s alternate none infinite running;
    }

    span {
      color: #ffffff;
      font-size: 24px;
      z-index: 2;
      @media screen and (max-width: 640px) {
        font-size: 1.4rem;
      }
    }
  }
}

@keyframes mouse {
  from {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    opacity: 1;
  }

  55% {
    opacity: 0.6;
  }

  to {
    transform: translateY(10px);
    -webkit-transform: translateY(10px);
    opacity: 0;
  }
}
.products2{
  .top_info {
  .img_main {
  .titel {
  .texty {
        > span {
          font-size: 4rem;
          white-space: pre-line;
          line-height: 1.2;
          @media screen and (max-width: 640px) {
           font-size: 2.5rem;
      }

        }
      }
}}}}
