.career {
    .content {
        .careertable{
    gap: 0;
}
        .creertable{
            gap: 32px;
        }
        .title_name {
            width: 100vw;
            margin: 0 auto;
            margin-top: 75px;
            margin-bottom: 49px;
            color: rgb(0, 0, 0);
            font-size: 48px;
            font-weight: 700;
            line-height: 58px;
            letter-spacing: 0px;
            text-align: left;
            transform: translateX(30px);
            @media screen and (max-width: 1000px) {
            font-size: 3rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
            
        }
            
        }

        .card_item {
            height: 25rem;
            background-color: #000;
             @media screen and (max-width: 1000px) {
         height: 100%;
      }
            .name{
                color: #fff;
            }
        }

        .opportunities_item {

            @media screen and (max-width: 1000px) {
        max-width: 100%;
      }
        }
    }
}
