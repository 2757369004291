.about_responsibility {
    .card_item {
        margin: 40px;
        width: 684.36px;
        height: 400px;
    }
    .top_info .img_main .titel .texty > span{
        @media (max-width:640px){
            font-size: 2.5rem;
        }
    }
}