.nav_link{
    .link{
        cursor: pointer;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
    }
    .nav {
        width: 100%;
        height: 32px;
        // border-bottom: 1px solid #ccc;
        border-bottom: 1px solid;
        border-image: linear-gradient(90deg, rgba(160, 182, 222, 0.00), rgba(160, 182, 222, 1.00) 49.792%, rgba(160, 182, 222, 0.00) 100%) 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: rgb(117, 117, 117);

            .home {
                margin-left: 20px;
                margin: 15px;
                background-image: url('../../static/svg/home.svg');
                height: 12px;
                width: 12px;
                cursor: pointer;
                background-repeat: no-repeat;
                background-position: center;
                @media (max-width:640px){
                     width: 24px;
                }
            }
            

        }

        .right {
            display: flex;
            align-items: center;
            width: 131px;
            height: 100%;
            border-left: 1px solid rgba(160, 182, 222, 0.451);
            font-size: 12px;
            color: rgb(117, 117, 117);

            .return {
                margin-left: 20px;
                margin: 15px;
                background-image: url('../../static/svg/returnTop.svg');
                height: 12px;
                width: 12px;
            }
        }

    }

}