.com_card_culture {
    width: 100%;
    position: relative;
    margin: 0;
    .img {
        margin: 0 auto;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        
        // background-image: url('../../static/img/item1.png');
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;   
            max-width: 38vw;         
            @media screen and (max-width: 800px) {
            max-width: 90vw;;
                }
        }
    }
    &:hover .bg_hover{
        display: block;
    }

    .infomation {
        color: #666;
        font-size: 2rem;
        font-weight: 400;
        min-height: 10rem;
        min-width: 100%;
        overflow: visible;
        position: relative;
        text-align: left;
        z-index: 9;
        background: #f1f5fb;
        padding: 1rem 2rem;
        box-sizing: border-box;
        max-width: 38vw;
    .name{
        color: rgb(0, 61, 166);
        font-weight: bold;
    }
        .info{
        font-size: 1rem;
        }
    }
    
}
.page_home .content_home {
    overflow: visible;
}