.products {

    .ant-col,
    .ant-col-md-24 {
        overflow: hidden;

    }

    .card_item {
        margin: 0 auto;
        height: 560px;
        @media (max-width: 720px){
             height: 380px;
         }
    }
    @media (min-width: 1600px){
    :where(.css-dev-only-do-not-override-ed5zg0).ant-col-xxl-24{
        flex: 0 0 50%;
    }
}
    
}