.about_leadership {

    .content {
        padding: 0 2vw;

        .leader_item {
            >.css-dev-only-do-not-override-ed5zg0{
                display: flex;
                flex-direction: row;
                gap: 24px;
                margin-top: 32px;
                justify-content: space-evenly;
            }
            .title {
                font-family: 'Barlow';
                font-style: normal;
                font-weight: 700;
                font-size: 3rem;
                /* identical to box height, or 125% */
                padding: 2rem 0px;
                text-align: center;
                text-transform: uppercase;
                border-bottom: 1px solid #D9D9D9;
                color: #000000;


                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 0;

                @media screen and (max-width: 750px) {
                    font-size: 8vw;
                }
            }

            .item {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                flex-direction: row;

                .infomation2 {
                    width:21vw;
                    max-width: 380px;
                    max-height: 280px;
                    background: #F1F5FB;
                    padding: 0 33px;
                    transition: all 0.5s;
                    padding:5% 24px;
                    @media screen and (max-width: 900px) {
                    width: 45vw;
                    max-width: 450px;
                    @media screen and (max-width: 500px) {
                    width:80vw;
                    max-width: 450px;
                    padding: 10% 24px;
                }
                }

                    &:hover {
                        background: #003DA6;

                        .position,
                        .name,
                        .info {
                            color: #fff;
                        }
                        .readmore {
                            color: #fff;
                            border-bottom: 2px solid #fff;
                            span{
                            border-right: 2px solid #fff;
                            border-bottom: 2px solid #fff;
                            
                        }
                        }
                        

                    }

                    .position {

                        margin-bottom: 16px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 12px;
                        /* identical to box height, or 86% */

                        text-align: left;

                        color: #000000;

                    }

                    .name {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 1.5rem;
                        line-height: 16px;
                        /* identical to box height, or 73% */

                        text-align: left;
                        text-transform: uppercase;

                        color: #003DA6;
                    }

                    .info {
                        margin-top: 1rem;
                        margin-bottom: 1rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 1.4;
                        height: 40px;
                        /* or 143% */


                        color: #8E8E8E;
                    }

                    
                }

                .img {
                    width: 18vw;
                    max-width: 450px;
                    max-height: 280px;
                    overflow: hidden;
                    position: relative;
                     @media screen and (max-width: 900px) {
                    width: 40vw;
                    max-width: 450px;
                    @media screen and (max-width: 500px) {
                    width: 100%;
                    height: 40vw;
                }
                }

                    img {
                        height: 100%;
                        position: absolute;
                        top: 0;
                        right: 50%;
                        transform: translateX(50%);
                    }

                    &::after {
                        content: '';
                        top: 0;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        z-index: 2;
                        background-color: rgba(0, 61, 166, 0.1);
                    }
                }
            }
        }

        .human_img {
            height: 700px;
            overflow: hidden;
            display: flex;
            align-items: center;
            flex-direction: column;
            position: relative;

            .select_control {
                display: flex;
                position: absolute;
                right: 0;
                cursor: pointer;

                .left {
                    background-color: rgb(0, 61, 166);
                    width: 66px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        transform: rotateZ(180deg);
                    }

                }

                .right {
                    background-color: rgb(0, 61, 166);
                    width: 66px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .bg {
                width: 100%;

                img {
                    margin: 0 auto;
                    width: 60%;
                    min-width: 500px;

                }
            }

            .name {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 360px;
                background-color: rgb(0, 61, 166, 0.2);
                color: rgb(255, 255, 255);
                font-size: 64px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 900;
            }
        }

        .infomation {
            height: 700px;
            display: flex;
            flex-direction: column;
            justify-content: end;

            .name {
                margin-left: 88px;
                font-size: 64px;
                font-weight: 700;
                display: flex;
                margin-bottom: 68px;

                .person_svg {
                    margin-top: 15px;
                    width: 16px;
                    height: 13px;
                    background-image: url('../../static/svg/person.svg');
                }
            }

            .info {

                padding-left: 88px;
                padding-right: 20px;

                display: flex;
                align-items: center;
                color: rgb(85, 85, 85);
                height: 360px;
                background-color: rgb(196, 196, 196, 0.2);

                div {
                    width: 540px;
                }
            }
        }

        .select {
            background: linear-gradient(270.00deg, rgba(160, 182, 222, 0.00), rgba(160, 182, 222, 0.2) 49.792%, rgba(160, 182, 222, 0.00) 100%);
            box-sizing: border-box;
            width: 100%;
            height: 189px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow-x: auto;

            img {
                height: 100%;
                object-fit: cover;

            }

            .select_img {
                margin: 0 12px;
                display: inline-block;
                width: 100px;
                height: 100px;

                span {
                    width: 100px;
                    display: inline-block;
                    text-align: center;
                    font-size: 14px;
                }


            }

            .activty {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgb(0, 61, 166, 0.7);
                    z-index: 4;

                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 14px;
                    height: 14px;
                    transform: translate(-50%);
                    background-image: url('../../static/svg/selected.svg');
                    z-index: 5;
                }
            }
        }
    }

}

.content_modal {
    .modal_content {
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 40px;
        gap:32px;

        .img {
            width: 280px;
            height: 320px;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .infomation2 {
            width: 450px;
        }

        .modal_name {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 14px;
            /* identical to box height, or 70% */

            text-transform: uppercase;

            color: #003DA6;
        }

        .modal_position {
            margin: 12px 0 24px 0;
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 1;
            /* identical to box height, or 75% */


            color: #8C8C8C;
        }

        .modal_info {

            font-family: 'Barlow';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            /* or 143% */


            color: #555555;
        }
    }

    .top {
        position: absolute;
        top: -20px;
        width: 837.55px;
        max-width: calc(100vw - 30px);
        height: 20px;
        background-color: #003DA6;
        margin-bottom: 20px;
    }
}
.about_leadership {
.content{
.leader_item{   
.leadertable{
    display:flex;
    flex-direction: row;
    gap: 24px;
    margin-top: 56px;
    margin-bottom: 56px;
    justify-content: space-evenly;
}}}}