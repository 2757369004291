.more {
    overflow: hidden;
    padding-top: 72px;
    color: #555;
    line-height: 1.6;
    @media screen and (max-width: 640px) {
       padding-top: 64px;
    }

    

    .title {
        background-color: rgb(184, 210, 255, 0.1);
        min-height: 180px;
        display: flex;
        justify-content: center;
        padding: 2rem;
        align-items: center;
        .title_box{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
            max-width: 1000px;
            width: 100%;
        }
        .title_info {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 1.5rem;
            text-align: left;
            max-width: 1000px;
            @media screen and (max-width: 640px) {
            font-size: 18px;
    }
        }

        .time {
            margin-bottom: 37px;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.2;
            padding: 15px;
            text-align: left;
            background-color: rgb(0, 61, 166);

        }

        .title_ps {
            line-height: 1.3;
            font-size: 14px;
            a{
            color: rgb(50, 50, 50);
            &:hover{
             color: rgb(42, 130, 228); 
            }
          }
        }
    }

    .info {
        padding: 20px;
        margin: 56px auto;
        max-width: 1000px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.6;
        letter-spacing: 0px;
        text-align: justify;
    }

    .img {
        width: 100%;
        height: 100%;
        justify-content: center;
        display: flex;

        img {
            width: 100%;
            max-width: 1000px;
            object-fit: cover;
            margin: 0 auto;
        }
    }
}
.contactfull{
    align-items: flex-start;
    color: #555;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 12px;
    margin: 4rem auto;
    position: relative;
    max-width: 1000px;
    padding-left: 2rem;
    padding-right: 2rem;
    p{
        margin-block-start:0;
        margin-block-end:0;
        display: flex;
        justify-content: left;
    }
    img{
        max-width: 100vw;
        margin: 0 auto;
        width: 100%;
    }
}