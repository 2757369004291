.router {
    overflow: hidden;

    

    

    
}

// .drawer_box {
//     #AcceptButton {
//         background: #0059ff !important;
//         display: inline-block !important;
//         font-size: 20px;
//         padding: 5px;
//         color: #fff;
//         border-radius: 10px;
//         cursor: pointer;
//     }

// }