.page_home {
  font-family: Barlow;

  :where(.css-dev-only-do-not-override-ed5zg0)[class^="ant-row"],
  :where(.css-dev-only-do-not-override-ed5zg0)[class*=" ant-row"],
  :where(.css-dev-only-do-not-override-ed5zg0)[class^="ant-col"],
  :where(.css-dev-only-do-not-override-ed5zg0)[class*=" ant-col"] {
    font-family: Barlow;
    overflow: hidden;
  }

  .nav_home {
    display: flex;
    align-items: end;
    align-items: center;
    position: sticky;
    min-height: 70px;
    top: 0;
    z-index: 999;
    background-color: #fff;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  
    @media screen and (max-width: 640px) {
      background-color: #000;
      min-height: 60px;
      .menu {
        display: none;
      }

      .seach_icon_box {
        display: none !important;
      }

      .seach_icon2 {
        display: block !important;
      }

      .tag {
        background-image: url("../../static/img/nav_tag2.png") !important;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .mask {
      width: 100vw;
      position: fixed;
      height: 100vh;
      background-color: #000;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 60px;

      .tag,
      .seach_icon2 {
        margin-top: 0 !important;
      }

      .nav_box {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        transition: all 0.5s;
        margin-top: 15px;
      }

      & > div {
        cursor: pointer;
        margin-top: 40px;
        width: 300px;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 19.84px;
        color: rgba(255, 255, 255, 1);
        text-align: center;

        .info_nav {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          color: rgba(173, 173, 173, 1);
          text-align: center;
        }
      }
    }

    .ant-menu-light.ant-menu-horizontal
      > .ant-menu-item-only-child:hover::after {
      transition: all 0.5s;
      transform: translateX(0);
      opacity: 1;
      width: 60%;
    }

    .ant-menu-light.ant-menu-horizontal > .ant-menu-item-only-child::after {
      opacity: 0;
      transform: translateX(-100%);
    }

    .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
      transform: translateX(0%);
      opacity: 1;
    }

    .tag {
      position: absolute;
      background-image: url("../../static/img/nav_tag.png");
      height: 48px;
      left: 29px;
      background-repeat: no-repeat;
      background-size: contain;
      width: 120px;
    }

    .menu {
      font-size: 14px;
      width: 40%;

      .ant-menu-light {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 17.36px;
        color: rgb(0, 0, 0);
        text-align: left;
        vertical-align: top;
      }

      .ant-menu-overflow {
        justify-content: flex-end;
        padding-right: 104px;
      }

      .ant-menu-horizontal {
        line-height: 72px;
        text-align: center;

        & > .ant-menu-item-selected {
          font-weight: 700;
          color: rgb(0, 61, 166);
        }
      }
    }

    .seach_icon {
      background-image: url("../../static/svg/search2.svg");
      background-repeat: no-repeat;
      width: 22.04px;
      height: 22px;
    }

    .seach_icon2 {
      display: none;
      position: absolute;
      right: 37.96px;
      cursor: pointer;
      background-image: url("../../static/svg/more.svg");
      background-repeat: no-repeat;
      width: 20px !important;
      height: 20px;
      background-position: center;
    }

    .seach_icon_box {
      position: absolute;
      right: 32px;
      top: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      opacity: 1;
      border-radius: 28px;
      padding: 5px;
      background: transparent;
      transition: all 0.5s;
      top: 16px;
      &:hover {
        background: rgba(42, 130, 228, 1);

        .seach_icon {
          background-size: contain;
          background-image: url("../../static/svg/seach.svg");
        }
      }
    }

    .nav_bottom_page {
      overflow: hidden;
      height: 0;
      opacity: 0;
      transition: all 0.5s;
      position: absolute;
      width: 100%;
      left: 0;
      top: 72px;
      background-color: #ffffff;
    }
  }

  .nav_home.blak {
    position: absolute;
    width: 100%;
    top: 0;
    background-color: transparent;
    border-bottom: 1px solid #cccccc3d;

    @media screen and (max-width: 1100px) {
      background-color: #000 !important;
      position: sticky;
      top: 0;
    }
    .seach_icon {
      background-image: url("../../static/svg/seach.svg") !important;
      background-repeat: no-repeat;
      width: 22.04px;
      height: 22px;
    }
    .ant-menu-light {
      color: rgb(255, 255, 255);
      background-color: transparent;
    }

    .tag {
      position: absolute;
      background-image: url("../../static/img/nav_tag2.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 120px;
      height: 48px;
      left: 29px;
      z-index: 1000;
      background-repeat: no-repeat;
      @media screen and (max-width: 640px) {
        height: 48px;
      }
    }
  }

  .content_home {
    overflow: hidden;
  }
}
.menutop {
  top: 0 !important;
  position: absolute !important; 
  width: 100% !important;
  margin-top: -60px !important;
  display: flex;
  align-items: center;
  background-color: #000;
  height: 60px;
}
.menu{
  :where(.css-dev-only-do-not-override-ed5zg0).ant-menu-horizontal{
    border-bottom:none;
  }
}
