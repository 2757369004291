.card_normal {
    height: 100%;
    width: 100%;
    position: relative;

    img {
        height: 100%;
        object-fit: contain;
    }

    .title {
        color: #fff;
        position: absolute;
        bottom: 23px;
        left: 44px;
        font-size: 24px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0px;
        text-align: left;
    }
}