.newsleft{
    display: flex;
    width: 50vw;
    @media (max-width:1200px){
        display: block;
    }
     @media (max-width:900px){
        width: 80vw;
    }
     @media (max-width:640px){
        width: 90vw;
    }
}

